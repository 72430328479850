<template>
	<div>
		<template>
			<div class="row item a" @click="navAskDetail()">
				<div class="left">
					<div class="title text-ellipsis" v-html="item.title"></div>
					<div class="row tags">
						<div class="row cc tag status">{{ item.answer.is_accept == 1 ? '已采纳' : '未采纳' }}</div>
						<div class="row cc tag">{{ item.major }}</div>
						<div class="row cc tag hot" v-if="item.is_hot == 1">热门</div>
						<div class="row cc tag" v-if="item.type == 1">积分悬赏</div>
						<div class="row cc tag" v-if="item.type == 2">现金悬赏</div>
					</div>
					<div class="answer-content">
						<div class="row cb answer-main">
							<div class="col answer-left">
								<div class="row user">
									<el-image :src="item.avatar" class="avatar"></el-image>
									<div class="nickname">{{ item.nickname }}</div>
									<div class="time">回答于 {{ item.answer.create_time_text }}</div>
								</div>
								<div class="content-text text-ellipsis-2" v-html="item.answer.content"></div>
							</div>
							<el-image v-if="showImg && item.answer.image" :src="img(item.answer.image)" fit="cover" class="answer-img"></el-image>
						</div>
					</div>
					<div class="row operate">
						<div class="row cc btn">
							<el-image :src="require('@/pc/assets/ask/5.png')" class="img"></el-image>
							<span>{{ item.answer.agree_num }} 点赞</span>
						</div>
						<div class="row cc btn">
							<el-image :src="require('@/pc/assets/ask/3.png')" class="img"></el-image>
							<span>{{ item.answer_num }} 个回答</span>
						</div>
						<div class="row cc btn">
							<el-image :src="require('@/pc/assets/ask/4.png')" class="img"></el-image>
							<span>分享</span>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		item: {
			type: Object,
			default: () => {
				return {};
			}
		},
		showImg: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		img(val) {
			return val => {
				if (val == '') {
					return '';
				} else {
					return this.$store.getters.oss(val.split(',')[0]);
				}
			};
		}
	},
	data() {
		return {};
	},
	methods: {
		navAskDetail() {
			this.$router.push({
				path: '/ask/detail',
				query: { id: this.item.id }
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './item.less';
</style>
