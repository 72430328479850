<template>
	<div>
		<template>
			<div class="row item a" @click="navAskDetail()">
				<div class="left">
					<div class="col que-user">
						<div class="row user">
							<el-image :src="item.avatar" class="avatar"></el-image>
							<div class="nickname">{{ item.nickname }}</div>
						</div>
						<div class="time">{{ item.create_time_text }}发布了新问题</div>
					</div>
					<div class="row cb detail">
						<div class="">
							<div class="title text-ellipsis">{{ item.title }}</div>
							<div class="row tags">
								<div class="row cc coin" v-if="item.type == 1">
									<el-image :src="require('@/assets/coin.png')" class="img" fit="contain"></el-image>
									<span>{{ item.point }}</span>
								</div>
								<div class="row cc red-packet" v-if="item.type == 2">
									<el-image :src="require('@/assets/redpacket.png')" class="img" fit="contain"></el-image>
									<span>{{ item.amount }}元</span>
								</div>
								<div class="row cc tag">{{ item.major }}</div>
								<div class="row cc tag hot" v-if="item.is_hot == 1">热门</div>
								<div class="row cc tag">{{ item.type == 1 ? '积分悬赏' : '现金悬赏' }}</div>
							</div>
							<div class="content text-ellipsis-2">{{ item.content }}</div>
							<div class="row operate">
								<div class="row cc btn">
									<el-image :src="require('@/pc/assets/ask/1.png')" class="img" fit="contain"></el-image>
									<span>{{ item.view_num }} 次浏览</span>
								</div>
								<div class="row cc btn">
									<el-image :src="require('@/pc/assets/ask/2.png')" class="img" fit="contain"></el-image>
									<span>{{ item.focus_num }} 条关注</span>
								</div>
								<div class="row cc btn">
									<el-image :src="require('@/pc/assets/ask/3.png')" class="img" fit="contain"></el-image>
									<span>{{ item.answer_num }} 个回答</span>
								</div>
								<div class="row cc btn">
									<el-image :src="require('@/pc/assets/ask/4.png')" class="img" fit="contain"></el-image>
									<span>分享</span>
								</div>
							</div>
						</div>
						<div class="right" v-if="showImg && item.image"><el-image :src="img(item.image)" class="img" fit="cover"></el-image></div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: () => {
				return {};
			}
		},
		showImg: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {};
	},
	computed: {
		img(val) {
			return val => {
				if (val == '') {
					return '';
				} else {
					return this.$store.getters.oss(val.split(',')[0]);
				}
			};
		}
	},
	methods: {
		navAskDetail() {
			this.$router.push({
				path: '/ask/detail',
				query: { id: this.item.id }
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './item.less';
.que-user {
	margin-bottom: 16px;
	.user {
		align-items: center;
		.avatar {
			width: 32px;
			height: 32px;
			border-radius: 50%;
		}
		.nickname {
			margin-left: 6px;
			color: #333333;
			font-size: 16px;
			font-weight: bold;
		}
	}
	.time {
		margin-top: 6px;
		color: #666666;
		font-size: 14px;
	}
}
.detail {
	background-color: #f4f4f4;
	border-radius: 4px;
	padding: 18px;
}
</style>
