<template>
	<div style="height: 100%;">
		<div class="row tab-view">
			<div class="row cc tab-item a" :class="{ active: tabIndex == 'newest' }" @click="tabChange('newest')">最新</div>
			<div class="row cc tab-item a" :class="{ active: tabIndex == 'answered' }" @click="tabChange('answered')">已解答</div>
			<div class="row cc tab-item a" :class="{ active: tabIndex == 'followed' }" @click="tabChange('followed')">关注</div>
			<div class="row cc tab-item a" :class="{ active: tabIndex == 'unanswered' }" @click="tabChange('unanswered')">待解答</div>
		</div>
		<div class="row tab-view" v-if="false">
			<div class="row cc tab-item a" :class="{ active: tabIndex == 3 }" @click="tabChange(3)">最新</div>
			<div class="row cc tab-item a" :class="{ active: tabIndex == 1 }" @click="tabChange(1)">推荐</div>
			<div class="row cc tab-item a" :class="{ active: tabIndex == 0 }" @click="tabChange(0)">关注</div>
			<div class="row cc tab-item a" :class="{ active: tabIndex == 2 }" @click="tabChange(2)">悬赏</div>
			<div class="right" v-show="tabIndex == 2">
				<div class="row cc status-view">
					<el-popover trigger="manual" placement="bottom" width="74" :visible-arrow="false" v-model="visibleStatus">
						<div class="status-content">
							<template v-for="(v, k) in status">
								<div class="row cc item a" :class="{ active: k == statusIndex }" @click="setStatus(k)">{{ v }}</div>
							</template>
						</div>
						<div class="row cc status-text a" slot="reference" @click="visibleStatus = !visibleStatus">
							<div class="title">全部</div>
							<i class="icon el-icon-caret-bottom"></i>
						</div>
					</el-popover>
				</div>
			</div>
		</div>
		<div style="height: 62px;"></div>
		<div class="main">
			<div class="list">
				<template v-for="(item, index) in list">
					<answer :item="item" :showImg="true" v-if="item.show_type == 1"></answer>
					<que :item="item" :showImg="true" v-else-if="item.show_type == 2"></que>
					<reward :item="item" :showImg="true" v-else></reward>
				</template>
			</div>
			<div class="paginate-view">
				<div class="row cc loading-view" v-show="loading && !list.length == 0">加载中……</div>
				<div class="skeleton-view" v-show="loading && list.length == 0">
					<el-skeleton animated class="skeleton-item" />
					<el-skeleton animated class="skeleton-item" />
					<el-skeleton animated class="skeleton-item" />
				</div>
				<div v-show="!loading && list.length == 0"><el-empty description=""></el-empty></div>
				<div class="row cc pagination" v-show="total > 0">
					<el-pagination background layout="prev,pager,next" :current-page="page" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
				</div>
			</div>
		</div>
		<!-- <div class="main"><el-empty description="请选择问题分类"></el-empty></div> -->
	</div>
</template>

<script>
import { mapState } from 'vuex';
import reward from '@/pc/components/ask/list/reward.vue';
import answer from '@/pc/components/ask/list/answer.vue';
import que from '@/pc/components/ask/list/que.vue';
export default {
	data() {
		return {
			visibleStatus: false,
			tabIndex: 'newest',
			status: ['积分悬赏', '现金悬赏'],
			statusIndex: 0,
			page: 1,
			pageSize: 15,
			list: [],
			loading: false,
			total: 0,
			filter: {}
		};
	},
	components: {
		reward,
		answer,
		que
	},
	computed: {
		...mapState(['majorInfo'])
	},
	created() {
		this.loadData();
	},
	methods: {
		tabChange(index) {
			this.tabIndex = index;
			this.visibleStatus = false;
			this.reload();
		},
		setStatus(index) {
			this.statusIndex = index;
			this.visibleStatus = false;
			this.$set(this.filter, 'awardtype', index + 1);
			this.reload();
		},
		reload() {
			this.page = 1;
			this.list = [];
			this.loadData();
		},
		pageChange(e) {
			this.list = [];
			this.page = e;
			this.loadData();
		},
		async loadData() {
			if (!this.loading) {
				window.scroll(0, 0);
				this.loading = true;
				let res = await this.$service.post('home/index_v2', {
					// 最新 (newest);已解答 (answered);关注 (followed);待解答 (unanswered)
					// 1=关注,2=推荐,3=悬赏,4=最新
					type: this.tabIndex,
					major_id: this.majorInfo.major_id,
					filter: this.filter,
					page: this.page,
					pageSize: this.pageSize
				});
				this.total = res.data.total;
				this.list = res.data.data;
				this.loading = false;
			}
		}
	}
};
</script>

<style>
.el-popover {
	min-width: 74px;
}
</style>
<style scoped lang="less">
@import './index.less';
</style>
